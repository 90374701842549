<template>
    <auth-layout>
        <auth-form back-route="auth-registration" :action="action" :loadingSubmit="isWaiting" :disableSubmit="isSuccess" :callback="submit">
            <template #header>
                <h1>{{ $t('auth_registration_domain.title') }}</h1>
                <p>{{ $t('auth_registration_domain.subtitle') }}</p>
            </template>

            <div class="tabs">
                <div class="tab" :class="{ active: isNSContent }" @click="setCurrentTab('ns')">
                    {{ $t('auth_registration_domain.ns.tab') }}
                </div>
                <div class="tab" :class="{ active: isFileContent }" @click="setCurrentTab('file')">
                    {{ $t('auth_registration_domain.file.tab') }}
                </div>
                <div class="tab" :class="{ active: isTagContent }" @click="setCurrentTab('tag')">
                    {{ $t('auth_registration_domain.tag.tab') }}
                </div>
            </div>

            <div v-if="isNSContent" class="content">
                <p>{{ $t('auth_registration_domain.ns.instruction') }}</p>
                <p>
                    {{ $t('auth_registration_domain.ns.after') }}
                    <strong>{{ $t('auth_registration_domain.ns.check') }}</strong>
                </p>
                <input-copy :value="ns" readonly />
            </div>

            <div v-if="isFileContent" class="content">
                <ol class="list">
                    <li class="item">
                        {{ $t('auth_registration_domain.file.point_1') }}
                        <a class="link" :href="fileUrl" target="_blank" rel="noreferrer">{{ fileName }}</a>
                    </li>
                    <li class="item">{{ $t('auth_registration_domain.file.point_2') }}</li>
                    <li class="item">{{ $t('auth_registration_domain.file.point_3') }}</li>
                </ol>
                <input-copy :value="filePath" readonly />
            </div>

            <div v-if="isTagContent" class="content">
                <p>
                    {{ $t('auth_registration_domain.tag.text_1') }}
                    <strong><{{ '/head' }}></strong>
                    {{ $t('auth_registration_domain.tag.text_2') }}
                </p>
                <input-copy :value="tag" readonly />
                <p>
                    {{ $t('auth_registration_domain.tag.text_3') }}
                    <strong>{{ $t('auth_registration_domain.tag.text_4') }}</strong>
                    {{ $t('auth_registration_domain.tag.text_5') }}
                </p>
                <p>{{ $t('auth_registration_domain.tag.text_6') }}</p>
            </div>

            <div class="error" v-if="isError">{{ $t('auth_registration_domain.error') }}</div>

            <template #button>
                {{ buttonTitle }}
            </template>

            <template #others>
                <text-button type="button" theme="success" full :disabled="!isSuccess" @click="complete">
                    {{ $t('auth_registration_domain.complete_button') }}
                </text-button>
            </template>
        </auth-form>
    </auth-layout>
</template>

<script>
    import AuthForm from '@/components/auth/AuthForm';
    import TextButton from '@/components/buttons/TextButton';
    import InputCopy from '@/components/inputs/InputCopy';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import { applicationChannelEvents, applicationChannel, leaveApplicationIdChannel } from '@/echo/application';
    import { verifyDomain } from '@/services/skinsPaymentApi';

    const tabs = {
        ns: 'ns',
        file: 'file',
        tag: 'tag',
    };

    const statuses = {
        initial: 'initial',
        waiting: 'waiting',
        error: 'error',
        success: 'success',
    };

    export default {
        name: 'RegistrationDomain',
        components: {
            AuthForm,
            TextButton,
            InputCopy,
            AuthLayout,
        },
        computed: {
            action() {
                const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');

                return `landing-api/applications/${applicationId}/verify-domain`;
            },
            applicationId() {
                return sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');
            },
            ns() {
                return sessionStorage.getItem('AUTH_REGISTRATION_NS');
            },
            fileName() {
                return sessionStorage.getItem('AUTH_REGISTRATION_FILE_NAME');
            },
            filePath() {
                return sessionStorage.getItem('AUTH_REGISTRATION_FILE_PATH');
            },
            fileUrl() {
                return sessionStorage.getItem('AUTH_REGISTRATION_FILE_URL');
            },
            tag() {
                return sessionStorage.getItem('AUTH_REGISTRATION_TAG');
            },
            buttonTitle() {
                return this.isSuccess ? this.$t('auth_registration_domain.submit_button_success') : this.$t('auth_registration_domain.submit_button');
            },
            isNSContent() {
                return this.currentTab === tabs.ns;
            },
            isFileContent() {
                return this.currentTab === tabs.file;
            },
            isTagContent() {
                return this.currentTab === tabs.tag;
            },
            isWaiting() {
                return this.status === statuses.waiting;
            },
            isError() {
                return this.status === statuses.error;
            },
            isSuccess() {
                return this.status === statuses.success;
            },
        },
        methods: {
            setCurrentTab(tab) {
                this.currentTab = tab;
            },
            submit() {
                const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');

                this.status = statuses.initial;

                return verifyDomain(applicationId).then(() => {
                    this.status = statuses.waiting;
                });
            },
            complete() {
                this.$router.push({ name: 'auth-login' });
            },
        },
        data() {
            return {
                currentTab: tabs.ns,
                status: statuses.initial,
            };
        },
        beforeRouteEnter(to, from, next) {
            const ns = sessionStorage.getItem('AUTH_REGISTRATION_NS');
            const fileName = sessionStorage.getItem('AUTH_REGISTRATION_FILE_NAME');
            const filePath = sessionStorage.getItem('AUTH_REGISTRATION_FILE_PATH');
            const fileUrl = sessionStorage.getItem('AUTH_REGISTRATION_FILE_URL');
            const tag = sessionStorage.getItem('AUTH_REGISTRATION_TAG');

            if (ns === null || fileName === null || filePath === null || fileUrl === null || tag === null) {
                next({ name: 'auth-registration' });
            } else {
                next();
            }
        },
        mounted() {
            const confirmed = sessionStorage.getItem('AUTH_REGISTRATION_CONFIRMED');

            if (confirmed) {
                this.status = statuses.success;
            } else {
                const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');

                applicationChannel(applicationId)
                    .listen(applicationChannelEvents.ApplicationDomainCheckedEvent, () => {
                        sessionStorage.setItem('AUTH_REGISTRATION_CONFIRMED', true);
                        this.status = statuses.success;
                    })
                    .listen(applicationChannelEvents.ApplicationDomainCheckFailedEvent, () => {
                        this.status = statuses.error;
                    });
            }
        },
        beforeDestroy() {
            const applicationId = sessionStorage.getItem('AUTH_REGISTRATION_APPLICATION_ID');

            leaveApplicationIdChannel(applicationId);

            const confirmed = sessionStorage.getItem('AUTH_REGISTRATION_CONFIRMED');

            if (confirmed) {
                sessionStorage.removeItem('AUTH_REGISTRATION_APPLICATION_ID');
                sessionStorage.removeItem('AUTH_REGISTRATION_CONFIRMED');
                sessionStorage.removeItem('AUTH_REGISTRATION_EMAIL');
                sessionStorage.removeItem('AUTH_REGISTRATION_FILE_NAME');
                sessionStorage.removeItem('AUTH_REGISTRATION_FILE_PATH');
                sessionStorage.removeItem('AUTH_REGISTRATION_FILE_URL');
                sessionStorage.removeItem('AUTH_REGISTRATION_NS');
                sessionStorage.removeItem('AUTH_REGISTRATION_TAG');
                sessionStorage.removeItem('AUTH_REGISTRATION_TIMER_END');
            }
        },
    };
</script>

<style lang="scss" scoped>
    .tabs {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 1.6rem;
    }

    .tab {
        height: 5.6rem;
        padding: 0 0.8rem;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #191f2e;
        color: #b9c6ce;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.4;
        text-align: center;
        transition: background 0.3s ease, color 0.3s ease;
        cursor: pointer;

        &:hover {
            background: #272e41;
        }

        &.active {
            background: #3760cb;
            color: #edf2ff;
        }
    }

    .content {
        margin-top: 2.4rem;
        display: grid;
        gap: 2.4rem;

        p {
            color: #8994b6;
            font-family: Gotham, sans-serif;
            font-size: 1.4rem;
            font-weight: 300;
            line-height: 2rem;

            strong {
                color: #4ab990;
                font-weight: 400;
            }
        }
    }

    .list {
        padding-left: 1.6rem;
        display: grid;
        gap: 2rem;
    }

    .item {
        list-style-type: decimal;
        color: #8994b6;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 2rem;

        a {
            color: #6bd4f5;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .link {
        word-break: break-word;
    }

    .error {
        margin-top: 2.4rem;
        padding: 1.6rem;
        border-radius: 0rem 0.8rem 0.8rem 0rem;
        border-left: 0.2rem solid #fe6a6a;
        background: rgba(254, 106, 106, 0.1);
        color: #fe6a6a;
        font-size: 1.2rem;
        font-weight: 325;
        line-height: 1.8rem;
    }
</style>

import echo from './index';

const channelName = applicationId => `${process.env.VUE_APP_ECHO_SERVER_CHANNEL_PREFIX}application.${applicationId}`;

export const applicationChannel = applicationId => echo.channel(channelName(applicationId));

export const leaveApplicationIdChannel = applicationId => echo.leave(channelName(applicationId));

export const applicationChannelEvents = {
    ApplicationDomainCheckedEvent: 'ApplicationDomainCheckedEvent',
    ApplicationDomainCheckFailedEvent: 'ApplicationDomainCheckFailedEvent',
};

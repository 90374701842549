<template>
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.7915 4.03253C17.3258 4.46969 17.4046 5.25722 16.9674 5.79152L8.6885 15.9102L3.72408 11.98C3.18281 11.5515 3.0914 10.7654 3.5199 10.2241C3.94841 9.68283 4.73457 9.59141 5.27584 10.0199L8.31143 12.4231L15.0325 4.20843C15.4697 3.67412 16.2572 3.59537 16.7915 4.03253Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'CheckIcon',
    };
</script>

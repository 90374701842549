<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.1458 3.24153L24.0317 4.49938C29.3115 5.33227 31.7832 7.50801 32.0018 11.7575C32.069 12.7944 31.985 13.9672 31.7832 15.2761L30.5557 23.2481C29.5132 29.8943 26.4698 32.138 19.8954 31.1011L12.0094 29.8433C9.73948 29.4863 7.99078 28.8744 6.71289 27.9565C4.2748 26.2397 3.56859 23.418 4.24117 19.0666L5.48543 11.0946C6.52793 4.44838 9.57134 2.20466 16.1458 3.24153ZM10.9669 11.9105C10.9669 13.8822 12.5643 15.497 14.5316 15.497C16.4821 15.497 18.0794 13.8822 18.0794 11.9105C18.0794 9.92171 16.4821 8.30691 14.5316 8.30691C12.5643 8.30691 10.9669 9.92171 10.9669 11.9105ZM32.6235 30.1662L35.1457 22.5001C36.6085 18.0296 36.2386 15.004 33.6828 12.8793C33.6828 13.6782 33.6155 14.5451 33.4474 15.5309L32.2199 23.503C31.1943 30.0132 28.2181 33.0388 22.6693 33.0388C21.7277 33.0388 20.7189 32.9538 19.6428 32.7838L11.74 31.526C10.3444 31.305 9.11693 30.982 8.05762 30.5571C9.16737 31.7979 10.8824 32.7838 13.2533 33.5827L20.8366 36.1324C27.1588 38.2572 30.5217 36.5574 32.6235 30.1662Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'CopyIcon',
    };
</script>

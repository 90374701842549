import CheckIcon from '@/components/icons/CheckIcon';
import CopyIcon from '@/components/icons/CopyIcon';

export default {
    components: {
        CheckIcon,
        CopyIcon,
    },
    computed: {
        iconComponent() {
            return this.copy.success ? 'check-icon' : 'copy-icon';
        },
    },
    methods: {
        selectContent(element) {
            const range = document.createRange();

            element.children.length ? range.selectNodeContents(element) : range.selectNode(element);

            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        },
        copyToClipboard(element, event) {
            element = element._isVue ? element.$el : element;

            const elements = element.children.length ? Array.from(element.children) : [element];

            window.navigator.clipboard.writeText(elements.map(node => node.value || node.textContent).join('\n')).then(() => {
                this.copy.success = true;

                setTimeout(() => {
                    const icon = Array.from(event.target.children).filter(child => 'svg' === child.tagName)[0] || null;

                    if (icon) {
                        icon.style.fill = this.copy.successColor;
                    }

                    this.selectContent(element);

                    setTimeout(() => (this.copy.success = false), 1000);
                }, 40);
            });
        },
    },
    data() {
        return {
            copy: {
                success: false,
                successColor: '#4db26a',
            },
        };
    },
};
